import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataPending: [],
            FieldPending: [],
            DataKembali: [],
            FieldKembali: [],
            Detail: { ID: "" },
            DetailSarana: [],
            q: "",
            D1: saiki("01"),
            D2: saiki(),
            JenisUser: "Guru",
            DataKelas: [],
            DataSarana: [],
            DataPeminjam: [],
            Status: "Pending"
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/sarpras_api", { act: "data peminjaman sarana", q: this.state.q, D1: this.state.D1, D2: this.state.D2, Status: this.state.Status }, true);
        if (sql.status == "sukses") {
            if (this.state.Status == "Pending") {
                this.setState({ DataPending: sql.data, FieldPending: sql.TableField });
            } else if (this.state.Status == "Dipinjam") {
                this.setState({ Data: sql.data, Field: sql.TableField });
            } else if (this.state.Status == "Kembali") {
                this.setState({ DataKembali: sql.data, FieldKembali: sql.TableField });
            }
        }
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/sarpras_api", { act: "detail peminjaman sarana", DocNumber: Detail.DocNumber }, true);
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DetailSarana: sql.detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    modalCariSarana() {
        openModal("modalSarana");
        this.handleCariSarana("");
    }

    async handleCariSarana(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/sarpras_api", { act: "data master sarana", q: val });
            if (sql.status == "sukses") this.setState({ DataSarana: sql.data });
        }, 500);
    }

    modalCariPeminjam() {
        openModal("modalPeminjam");
        this.handleCariPeminjam("");
    }

    async handleCariPeminjam(val) {
        let JenisUser = this.state.JenisUser;
        let sql = await api("controler/sarpras_api", { act: "data peminjam", JenisUser: JenisUser, q: val }, true);
        if (sql.status == "sukses") this.setState({ DataPeminjam: sql.data });
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Peminjaman Sarana</div>
                            <div className="col-md-6 d-flex align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <DateRangeBox
                                    defaultValue={[this.state.D1, this.state.D2]}
                                    onValueChanged={this.onCurrentValueChange}
                                />
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => {
                                    this.setState({ Status: "Pending" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Menunggu Persetujuan</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => {
                                    this.setState({ Status: "Dipinjam" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Sedang Dipinjam</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => {
                                    this.setState({ Status: "Kembali" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Sudah Kembali</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.DataPending}
                                    thead={this.state.FieldPending}
                                    group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field}
                                    group={[{ cap: "Nama Peminjam", sort: "NamaPeminjam" }]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.DataKembali}
                                    thead={this.state.FieldKembali}
                                    group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah peminjaman sarana" : "edit peminjaman sarana"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="DocNumber" value={this.state.Detail.DocNumber} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Peminjaman Sarana" : "Edit Peminjaman Sarana"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Peminjaman</label>
                                                <input type="date" className="form-control" id="Tanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Peminjam</label>
                                                <div className='input-group'>
                                                    <input type="hidden" name="IDPeminjam" value={this.state.Detail.IDPeminjam || "0"} />
                                                    <input type="text" className="form-control" id="edtNamaPeminjam" name="NamaPeminjam" value={this.state.Detail.NamaPeminjam || ""} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.NamaPeminjam = e.target.value;
                                                        this.setState({ Detail: data });
                                                    }} required />
                                                    <button className='btn btn-default' type='button' onClick={() => this.modalCariPeminjam()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Nama peminnjam</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                    </div>

                                    <p></p>
                                    <button className='btn btn-default' type='button' onClick={(e) => this.modalCariSarana()} >Tambah Sarana</button>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Kode</th>
                                                    <th>Nama</th>
                                                    <th>Qty</th>
                                                    <th>Note</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DetailSarana.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <input type='hidden' name="IDSarana[]" value={tr.IDSarana} readOnly />
                                                                <input type="text" name="Kode[]" className='form-control form-control-sm' value={tr.Kode} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="NamaSarana[]" className='form-control form-control-sm' value={tr.NamaSarana} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="Qty[]" className='form-control form-control-sm' value={tr.Qty} onChange={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    data[i].Qty = e.target.value;
                                                                    this.setState({ DetailSarana: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="Notes[]" className='form-control form-control-sm' value={tr.Notes} onChange={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    data[i].Notes = e.target.value;
                                                                    this.setState({ DetailSarana: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-danger' type='button' onClick={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    let temp = [];
                                                                    for (let dd in data) if (dd != i) temp.push(data[dd]);
                                                                    this.setState({ DetailSarana: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalSarana" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Master Sarana</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" onChange={(e) => this.handleCariSarana(e.target.value)} />
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Kode</th>
                                                <th>Nama</th>
                                                <th>Jumlah</th>
                                                <th>Keterangan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataSarana.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => {
                                                        let data = this.state.DetailSarana;
                                                        data.push({ Kode: tr.Kode, NamaSarana: tr.Nama, Qty: 1, IDSarana: tr.ID, Notes: "" });
                                                        this.setState({ DetailSarana: data });
                                                        document.getElementById('btnTutupModalSarana').click();
                                                    }}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Qty}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSarana'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalPeminjam" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Peminjam</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-start gap-1">
                                    <select className='form-select' value={this.state.JenisUser} onChange={(e) => {
                                        this.setState({ JenisUser: e.target.value });
                                        setTimeout(() => {
                                            this.handleCariPeminjam("");
                                        }, 1000);
                                    }}>
                                        <option value="Guru">Guru</option>
                                        <option value="Murid">Murid</option>
                                        <option value="Staff">Staff</option>
                                    </select>
                                    <input type="search" className="form-control" onChange={(e) => this.handleCariPeminjam(e.target.value)} />
                                </div>
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>NIP / NISN</th>
                                                <th>Nama</th>
                                                <th>Telp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataPeminjam.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => {
                                                        let data = this.state.Detail;
                                                        data.IDPeminjam = tr.ID;
                                                        data.NamaPeminjam = tr.Nama;
                                                        this.setState({ Detail: data });
                                                        document.getElementById('btnTutupModalPeminjam').click();
                                                    }}>
                                                        <td>{tr.NIP}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Telp}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalPeminjam'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus peminjaman sarana" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Master Sarpras</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
