import React, { Fragment } from 'react';
import { api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataGroup: [],
            Detail: {},
            TableField: [],
            q: "",
            Akses: true,
        };
        this.draggedItem = null;
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/mastermenu_api", { act: "data master menu", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/mastermenu_api", { act: "data group master menu", ID: Detail.ID }, true);
        let TableField = sql.data.TableField == undefined ? [] : JSON.parse(sql.data.TableField);
        this.setState({ Detail: sql.data || {}, DataGroup: sql.group, TableField: TableField });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    handleDeleteTable(i) {
        let data = this.state.TableField, temp = [];
        for (let dd in data) if (dd != i) temp.push(data[dd]);
        this.setState({ TableField: temp });
    }

    onDragStart = (event, index) => {
        this.draggedItem = this.state.TableField[index];
        event.dataTransfer.effectAllowed = "move";
    };

    onDragOver = (index) => {
        const draggedOverItem = this.state.TableField[index];
        if (this.draggedItem === draggedOverItem) {
            return;
        }
        let items = this.state.TableField.filter(item => item !== this.draggedItem);
        items.splice(index, 0, this.draggedItem);
        this.setState({ TableField: items });
    };

    onDragEnd = () => {
        this.draggedItem = null;
    };

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {
                            this.state.Akses == true ?
                                <Fragment>
                                    <div className="row">
                                        <div className="col-md-8 main-title">Master Menu</div>
                                        <div className="col-md-4 d-flex gap-1">
                                            <div className="input-group gap-2">
                                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                                <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                                <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <RendTables tbody={this.state.Data}
                                        thead={this.state.Field}
                                        group={[
                                            { sort: "Group", cap: "Gruop" }
                                        ]}
                                        opt={[
                                            { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                            { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                        ]}
                                    />
                                </Fragment>
                                : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermenu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master menu" : "edit master menu"} />
                                <input type='hidden' name="TableField" value={JSON.stringify(this.state.TableField)} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Group</label>
                                                <select className='form-select' name="GroupID" value={this.state.Detail.GroupID || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.GroupID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required>
                                                    <option value="0">ROOT</option>
                                                    {
                                                        this.state.DataGroup.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className="invalid-feedback">Silahkan pilih data</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Nama</label>
                                                <input type="text" className="form-control" name='Nama' id="edtNama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan Masukan Nama</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Path</label>
                                                <input type="text" className="form-control" name='Path' id="edtPath" value={this.state.Detail.Path || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Path = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan Masukan Path</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Icon</label>
                                                <input type="text" className="form-control" name='Icon' id="edtIcon" value={this.state.Detail.Icon || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Icon = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan Masukan Icon</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>No Urut</label>
                                                <input type="text" className="form-control" name='IsOrder' id="edtIsOrder" value={this.state.Detail.IsOrder || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IsOrder = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className="invalid-feedback">Silahkan Masukan IsOrder</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea type="text" className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className="invalid-feedback">Silahkan Masukan Keterangan</div>
                                    </div>
                                    <p></p>
                                    <div className="d-flex gap-2">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name='IsGroup' type="checkbox" role="switch" id="chkIsGroup" checked={this.state.Detail.IsGroup == 1 ? true : false} onChange={(e) => {
                                                let data = this.state.Detail;
                                                data.IsGroup = e.target.checked == true ? 1 : 0;
                                                this.setState({ Detail: data });
                                            }} />
                                            <label className="form-check-label" htmlFor="chkIsGroup">Group</label>
                                        </div>

                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name='Status' type="checkbox" role="switch" id="chkStatus" checked={this.state.Detail.Status == 1 ? true : false} onChange={(e) => {
                                                let data = this.state.Detail;
                                                data.Status = e.target.checked == true ? 1 : 0;
                                                this.setState({ Detail: data });
                                            }} />
                                            <label className="form-check-label" htmlFor="chkStatus">{this.state.Detail.Status == 1 ? "Aktif" : "Tidak Aktif"}</label>
                                        </div>
                                    </div>
                                    <p></p>
                                    <button type="button" className="btn btn-default" id="btnAddTable" onClick={() => {
                                        let data = this.state.TableField;
                                        data.push({ cap: "", sort: "", type: "str" });
                                        this.setState({ TableField: data });
                                    }}>Tambah Field</button>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead className='bg-thead'>
                                                <tr>
                                                    <th></th>
                                                    <th>Caption</th>
                                                    <th>Field</th>
                                                    <th>Type</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.TableField.map((tr, i) => (
                                                    <tr
                                                        key={i}
                                                        draggable={true}
                                                        onDragStart={(e) => this.onDragStart(e, i)}
                                                        onDragOver={() => this.onDragOver(i)}
                                                        onDragEnd={this.onDragEnd}
                                                    >
                                                        <td>
                                                            <i className="fas fa-grip-horizontal" style={{ cursor: "all-scroll" }}></i>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={tr.cap}
                                                                onChange={(e) => {
                                                                    let data = this.state.TableField;
                                                                    data[i].cap = e.target.value;
                                                                    this.setState({ TableField: data });
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={tr.sort}
                                                                onChange={(e) => {
                                                                    let data = this.state.TableField;
                                                                    data[i].sort = e.target.value;
                                                                    this.setState({ TableField: data });
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                className="form-select"
                                                                value={tr.type}
                                                                onChange={(e) => {
                                                                    let data = this.state.TableField;
                                                                    data[i].type = e.target.value;
                                                                    this.setState({ TableField: data });
                                                                }}
                                                            >
                                                                <option value="str">String</option>
                                                                <option value="index">Index</option>
                                                                <option value="number">Number</option>
                                                                <option value="date">Date</option>
                                                                <option value="datetime">Date Time</option>
                                                                <option value="month">Month</option>
                                                                <option value="json">Json</option>
                                                                <option value="html">Html</option>
                                                                <option value="opsi">Opsi</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-default" onClick={() => this.handleDeleteTable(i)}>
                                                                <i className="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermenu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master menu" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Menu</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus menu !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
