import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataMurid: [],
            DataKelas: [],
            Detail: {},
            q: "",
            Akses: true,
            ArrMurid: [],
            DataCariMurid: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/masterkelas_api", { act: "data perpindahan kelas", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalPindah(Detail = {}) {
        let sql = await api("controler/masterkelas_api", { act: "detail perpindahan kelas", ID: Detail.ID }, true);
        this.setState({ Detail: Detail, DataKelas: sql.kelas });
        openModal("modalFormPerpindahanKelas");
    }

    async handleModalKenaikan(Detail = {}) {
        let sql = await api("controler/masterkelas_api", { act: "detail perpindahan kelas", ID: Detail.ID }, true);
        this.setState({ Detail: Detail, DataKelas: sql.kelas });
        openModal("modalFormKenaikanKelas");
    }

    handlePilihMenu(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataMurid;
        let ArrMurid = [];
        for (let dd in Menu) {
            if (Menu[dd].ID == data.ID) {
                Menu[dd].checked = chk.checked;
            }
            if (Menu[dd].checked == true) ArrMurid.push(Menu[dd]);
        }
        this.setState({ DataMurid: Menu, ArrMurid: ArrMurid });
    }

    handlePilihMurid(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataCariMurid;
        for (let dd in Menu) if (Menu[dd].ID == data.ID) Menu[dd].checked = chk.checked;
        this.setState({ DataCariMurid: Menu });
    }

    async handleCariMurid(ID) {
        let sql = await api("controler/masterkelas_api", { act: "data murid mutasi kelas", IDKelas: ID }, true);
        let data = [], ArrMurid = [];
        for (let dd of sql.data) {
            dd.checked = true;
            data.push(dd);
            ArrMurid.push({ IDMurid: dd.ID, NamaMurid: dd.NAMA, NISN: dd.NISN });
        }
        if (sql.status == "sukses") this.setState({ DataMurid: data, ArrMurid: ArrMurid });
    }

    async modalCariMurid() {
        let sql = await api("controler/masterkelas_api", { act: "data master murid all" }, true);
        if (sql.status == "sukses") {
            this.setState({ DataCariMurid: sql.data });
            openModal("modalCariMurid");
        }
    }

    handleHapusMurid(data) {
        let DataMurid = this.state.DataMurid;
        let TempData = [], ArrMurid = [];
        for (let dd of DataMurid) {
            if (dd.ID != data.ID) {
                TempData.push(dd);
                ArrMurid.push({ IDMurid: dd.ID, NamaMurid: dd.NAMA, NISN: dd.NISN });
            }
        }
        this.setState({ DataMurid: TempData, ArrMurid: ArrMurid });
    }

    handleSaveMurid() {
        let data = this.state.DataCariMurid;
        let Temp = [], ArrMurid = [];
        for (let dd of data) {
            if (dd.checked == true) {
                Temp.push(dd);
                ArrMurid.push({ IDMurid: dd.ID, NamaMurid: dd.NAMA, NISN: dd.NISN });
            }
        }
        this.setState({ DataMurid: Temp, ArrMurid: ArrMurid });
        document.getElementById('btnTutupModalCariMurid').click();
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ? <Fragment>
                            <div className="row">
                                <div className="col-md-6 main-title">Perpindahan Kelas</div>
                                <div className="col-md-6 d-flex">
                                    <div className="input-group">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleModalPindah()}>+ Pindah Kelas</button>
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleModalKenaikan()}>+ Kenaikan Kelas</button>
                                        <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Kelas' />
                                        <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                group={[
                                    { sort: "DocType", cap: "Jenis Mutasi" }
                                ]} />
                        </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk kenaikan kelas */}
                <div className="modal fade" id="modalFormKenaikanKelas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah kenaikan kelas" : "edit kenaikan kelas"} />
                                <input type="hidden" name="Murid" value={JSON.stringify(this.state.ArrMurid)} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Kenaikan Kelas" : "Edit Kenaikan Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Dari Kelas</label>
                                                <select className='form-select' name='DariKelasID' value={this.state.Detail.DariKelasID} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.DariKelasID = e.target.value;
                                                    this.setState({ Detail: data });
                                                    this.handleCariMurid(e.target.value);
                                                }}>
                                                    <option value=''>Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Ke Kelas</label>
                                                <select className='form-select' name='KeKelasID' value={this.state.Detail.KeKelasID} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.KeKelasID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }}>
                                                    <option value=''>Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea type="text" className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className="invalid-feedback">Silahkan isi keterangan</div>
                                    </div>
                                    <p></p>
                                    <button type="button" className="btn btn-default" id="btnAddMurid" onClick={() => this.modalCariMurid()}>Tambah Murid</button>
                                    <p></p>
                                    <RendTables
                                        tbody={this.state.DataMurid}
                                        thead={[
                                            { cap: "", sort: "", type: "opsi" },
                                            { cap: "NISN", sort: "NISN", type: "str" },
                                            { cap: "NAMA", sort: "NAMA", type: "str" },
                                            { cap: "ALAMAT", sort: "ALAMAT", type: "str" },
                                            { cap: "TELP", sort: "TELP", type: "str" },
                                        ]}
                                        opt={[
                                            { icon: "fas fa-trash", fn: (e) => this.handleHapusMurid(e) },
                                        ]}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormSemester'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk pindah kelas */}
                <div className="modal fade" id="modalFormPerpindahanKelas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah perpindahan kelas" : "edit perpindahan kelas"} />
                                <input type="hidden" name="Murid" value={JSON.stringify(this.state.ArrMurid)} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Perpindahan Kelas" : "Edit Perpindahan Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Ke Kelas</label>
                                        <select className='form-select' name='KeKelasID' value={this.state.Detail.KeKelasID} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.KeKelasID = e.target.value;
                                            this.setState({ Detail: data });
                                        }}>
                                            <option value=''>Silahkan pilih kelas</option>
                                            {
                                                this.state.DataKelas.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea type="text" className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className="invalid-feedback">Silahkan isi keterangan</div>
                                    </div>
                                    <p></p>
                                    <button type="button" className="btn btn-default" id="btnAddMurid" onClick={() => this.modalCariMurid()}>Tambah Murid</button>
                                    <p></p>
                                    <RendTables
                                        tbody={this.state.DataMurid}
                                        thead={[
                                            { cap: "", sort: "", type: "opsi" },
                                            { cap: "NISN", sort: "NISN", type: "str" },
                                            { cap: "NAMA", sort: "NAMA", type: "str" },
                                            { cap: "ALAMAT", sort: "ALAMAT", type: "str" },
                                            { cap: "TELP", sort: "TELP", type: "str" },
                                        ]}
                                        opt={[
                                            { icon: "fas fa-trash", fn: (e) => this.handleHapusMurid(e) },
                                        ]}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormSemester'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Murid</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataCariMurid}
                                    thead={[
                                        { cap: "", sort: "", type: "opsi" },
                                        { cap: "NISN", sort: "NISN", type: "str" },
                                        { cap: "NAMA", sort: "NAMA", type: "str" },
                                        { cap: "Kelas", sort: "Kelas", type: "str" },
                                        { cap: "Alamat", sort: "ALAMAT", type: "str" },
                                    ]}
                                    group={[
                                        { sort: "Kelas", cap: "Kelas" }
                                    ]}
                                    opt={[
                                        { icon: "checkbox", fn: (e) => this.handlePilihMurid(e, {}) },
                                    ]}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMurid'>Batal</button>
                                <button type="submit" className="btn btn-default" onClick={() => this.handleSaveMurid()}><i className="fas fa-trash"></i> Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus semester" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Menu</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus Kelas ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
