import React from "react";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager, Scrolling } from 'devextreme-react/data-grid';
// import 'devextreme/dist/css/dx.light.css';
// import 'devextreme/dist/css/dx.greenmist.css';
// import 'devextreme/dist/css/dx.darkviolet.css';
import { parseJsonArr } from '../Modul';

class RendTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thead: [],
            tbody: [],
            opt: [],
            group: [],
            event: {},
            colorTheme: localStorage.getItem("ColorTheme"),
            limit: 100
        };
    }

    componentDidMount() {
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        let themeLink = document.getElementById('theme-link');
        if (ColorTheme == "dark") {
            themeLink.href = 'https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.darkviolet.css';
            this.setState({ colorTheme: "dark" });
        } else {
            themeLink.href = "https://cdn.jsdelivr.net/npm/devextreme@23.2/dist/css/dx.greenmist.css"
            this.setState({ colorTheme: "light" });
        }
        this.handleRender();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thead !== this.props.thead || prevProps.tbody !== this.props.tbody) this.handleRender();
    }

    handleRender() {
        let thead = this.props.thead;
        let tbody = this.props.tbody;
        let opt = this.props.opt || [];
        let group = this.props.group || [];
        let limit = this.props.limit || 100;
        let event = this.props.event || {};
        let colorTheme = localStorage.getItem("ColorTheme");
        this.setState({ thead, tbody, opt, group, colorTheme, limit, event: event });
    }

    handleActive(e) {
        var activeElements = document.querySelectorAll('.table-active');
        activeElements.forEach(function (element) {
            element.classList.remove('table-active');
        });
        if (e.target.tagName == "TD") {
            e.target.parentNode.classList.add("table-active");
        }
    }

    render() {
        return (
            <div className="table-responsive">
                <DataGrid
                    dataSource={this.state.tbody}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    focusedRowEnabled={true}
                    keyExpr="ID"
                    theme={this.state.colorTheme == "dark" ? "dx-theme-darkviolet" : "dx-theme-greenmist"}
                    onRowClick={this.state.event.click || ""}
                >
                    {
                        this.state.thead.map((tr, ii) => {
                            if (tr.type == "number") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                            } else if (tr.type == "date") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format={{
                                    type: 'custom',
                                    formatter: (date) => {
                                        const options = {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        };
                                        return new Intl.DateTimeFormat('id-ID', options).format(new Date(date));
                                    }
                                }} key={ii} />
                            } else if (tr.type == "month") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format={{
                                    type: 'custom',
                                    formatter: (date) => {
                                        const options = {
                                            month: 'short',
                                            year: 'numeric'
                                        };
                                        return new Intl.DateTimeFormat('id-ID', options).format(new Date(date));
                                    }
                                }} key={ii} />
                            } else if (tr.type == "datetime") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="datetime" format={{
                                    type: 'custom',
                                    formatter: (date) => {
                                        const options = {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: false
                                        };
                                        return new Intl.DateTimeFormat('id-ID', options).format(new Date(date));
                                    }
                                }} key={ii} />
                            } else if (tr.type == "html") {
                                return (
                                    <Column
                                        dataField={tr.sort}
                                        caption={tr.cap}
                                        key={ii}
                                        cellRender={(cellData) => (
                                            <div dangerouslySetInnerHTML={{ __html: cellData.value }} />
                                        )}
                                    />
                                );
                            } else if (tr.type == "opsi") {
                                return <Column
                                    caption={tr.cap} key={ii}
                                    cellRender={(rowData) => (
                                        <div className="d-flex gap-1">
                                            {
                                                this.state.opt.map((opt, i) => {
                                                    if (opt.icon != "checkbox") {
                                                        let color = opt.color || "color-default";
                                                        return <span tooltip={opt.cap} flow={i == 0 ? "right" : "left"} >
                                                            <i className={opt.icon + " pointer " + color} key={i} onClick={() => opt.fn(rowData.data)} />
                                                        </span>
                                                    } else {
                                                        return (
                                                            <div className="form-check form-switch" key={i}>
                                                                <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id={"chkTable" + rowData.data.ID} checked={rowData.data.checked == 1 ? true : false} onChange={(e) => opt.fn(rowData.data)} />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    )}
                                />
                            } else if (tr.type == "checkbox") {
                                return (
                                    <Column key={ii} caption={<input type="checkbox" />}
                                        cellRender={(rowData) => (
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" checked={rowData.data.checked === 1} />
                                            </div>
                                        )}
                                    />
                                )
                            } else if (tr.type == "index") {
                                return <Column caption={tr.cap} alignment="center" cellRender={(data) => { return data.rowIndex + 1; }} key={ii} />
                            } else if (tr.type == "json") {
                                return (
                                    <Column
                                        key={ii}
                                        caption={tr.cap}
                                        cellRender={(rowData) => {
                                            let jsonData = parseJsonArr(rowData.data[tr.sort]);

                                            if (Array.isArray(jsonData)) {
                                                return (
                                                    <ul>
                                                        {jsonData.map((item, index) => (
                                                            <li key={index}>
                                                                {Object.keys(item).map((key, i) => (
                                                                    <div key={i}>
                                                                        <strong>{key}:</strong> {item[key]}
                                                                    </div>
                                                                ))}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                );
                                            } else {
                                                return <div>Invalid Data</div>;
                                            }
                                        }}
                                    />
                                );
                            } else {
                                return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                            }
                        })
                    }
                    {
                        this.state.group.map((gp, i) => {
                            return <Column dataField={gp.sort} caption={gp.cap} groupIndex={i} key={i} />
                        })
                    }
                    <Paging defaultPageSize={this.state.limit} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Summary>
                        <TotalItem column="ID" summaryType="count" />
                        <GroupItem column="ID" summaryType="count" displayFormat="{0} Data" />
                    </Summary>
                    <Pager allowedPageSizes={[50, 100, 150, 200, 1000]} showPageSizeSelector={true} />
                </DataGrid>
            </div >
        );
    }

}

export default RendTable;