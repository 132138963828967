import React, { Fragment } from 'react';
import { api, submitForm, openModal, tanggalIndo, exportData, importData, checkDateFormat, saiki } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
import QRCode from 'qrcode.react';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            D1: saiki("01"),
            D2: saiki(),
            DataAnggota: [],
            DataAnggotaTerpilih: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/perpus_api", { act: "data pengunjung perpus", q: this.state.q, D1: this.state.D1, D2: this.state.D2 }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/perpus_api", { act: "detail pengunjung perpus", DocNumber: Detail.DocNumber });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    async handleCariAnggota(val) {
        let sql = await api("controler/perpus_api", { act: "data master anggota perpus", q: val });
        if (sql.status == "sukses") this.setState({ DataAnggota: sql.data });
    }

    handleModalCariAnggota() {
        this.handleCariAnggota("");
        openModal("modalCariAnggota");
    }

    handlePilihAnggota(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataAnggota;
        for (let dd in Menu) if (Menu[dd].ID == data.ID) Menu[dd].checked = chk.checked;
        this.setState({ DataAnggota: Menu });
    }

    handleSubmitAnggota(e, data) {
        let Anggota = this.state.DataAnggota;
        let ArrData = [];
        for (let dd of Anggota) if (dd.checked == true) ArrData.push(dd);
        this.setState({ DataAnggotaTerpilih: ArrData });
    }

    handleRendQR() {
        openModal("modalQR");
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-7 main-title">Pengunjung Perpus</div>
                            <div className="col-md-5 d-flex align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <DateRangeBox
                                    defaultValue={[this.state.D1, this.state.D2]}
                                    onValueChanged={this.onCurrentValueChange}
                                />
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleRendQR()} ><i className="fas fa-qrcode"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "Tanggal", cap: "Tanggal" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-ellipsis-v", fn: (e) => this.handleModalProses(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk absen dengan QRCODE */}
                <div className="modal fade" id="modalQR" tabIndex="-1" aria-labelledby="modalAbsen" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Absen Dengan Scan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body d-flex justify-content-center align-items-cennter">
                                <QRCode value={saiki()} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="tambah pengunjung perpus" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Tambah Pengunjung Perpus</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className='form-control' id="edtDocDate" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Anggota</label>
                                                <div className="input-group">
                                                    <input type="text" className='form-control' id="edtNamaAnggota"
                                                        onFocus={() => this.handleModalCariAnggota()}
                                                        readOnly />
                                                    <button type="button" className="btn btn-default" id="btnCariAnggota" onClick={() => this.handleModalCariAnggota()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Nama Anggota</div>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Kode</th>
                                                    <th>Nama</th>
                                                    <th>Alamat</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataAnggotaTerpilih.map((tr, i) => {
                                                        if (tr.checked == true) {
                                                            return (<tr key={i}>
                                                                <td>
                                                                    <input type="hidden" name="IDAnggota[]" value={tr.ID} />
                                                                    <input type="text" className="form-control" name="KodeAnggota[]" value={tr.Kode} readOnly />
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control" name="NamaAnggota[]" value={tr.Nama} readOnly />
                                                                </td>
                                                                <td>{tr.Alamat}</td>
                                                            </tr>)
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariAnggota" tabIndex="-1" aria-labelledby="modalCariAnggota" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content shadow">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Anggota</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataAnggota}
                                    thead={[
                                        { cap: "Pilih Semua", sort: "checkbox", type: "opsi" },
                                        { cap: "Kode", sort: "Kode", type: "str" },
                                        { cap: "Nama", sort: "Nama", type: "str" },
                                        { cap: "Alamat", sort: "Alamat", type: "str" }
                                    ]}
                                    opt={[
                                        { icon: "checkbox", fn: (e) => this.handlePilihAnggota(e) }
                                    ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariAnggota'>Batal</button>
                                <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={(e) => this.handleSubmitAnggota(e)}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus pengunjung perpus" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Pengunjung</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus pengunjung ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
