import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host, tanggalIndo } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
import imgLoading from '../assets/img/loading-upload.gif';
import imgUpload from '../assets/img/img-upload.png';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: { ID: "" },
            q: "",
            D1: saiki("01"),
            D2: saiki(),
            DataMurid: [],
            Status: "Pending",
            Images: imgUpload
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/tatausaha_api", { act: "data izin murid", q: this.state.q, D1: this.state.D1, D2: this.state.D2, Status: this.state.Status }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/tatausaha_api", { act: "detail izin murid", ID: Detail.ID }, true);
        let Images = sql.data != false ? host.replace("siamik/", "") + sql.data.File : imgUpload;
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, Images: Images });
        openModal("modalFormMenu");
    }

    async handleCariMurid(val) {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/tatausaha_api", { act: "data master murid", q: val }, true);
            if (sql.status == "sukses") this.setState({ DataMurid: sql.data, Image: host + sql.data.File });
        }, 500);
    }

    async handleDetail(data) {
        let sql = await api("controler/tatausaha_api", { act: "detail izin murid", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data });
            openModal("modalDetail");
        }

    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    async handlePilihImage(e) {
        this.setState({ Images: imgLoading });
        let File = await openFile(e, true);
        let Gambar = File[0].img;
        this.setState({ Images: Gambar });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Izin Siswa</div>
                            <div className="col-md-6 d-flex align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <DateRangeBox
                                    defaultValue={[this.state.D1, this.state.D2]}
                                    onValueChanged={this.onCurrentValueChange}
                                />
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-info", fn: (e) => this.handleDetail(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/tatausaha_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah izin murid" : "edit izin murid"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="IDUser" value={this.state.Detail.IDUser} />
                                <input type='hidden' name="NISN" value={this.state.Detail.NISN} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Izin" : "Edit Izin"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Tanggal</label>
                                                <input type="date" className="form-control" name='Tanggal' id="edtTanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className="invalid-feedback">Silahkan pilih tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Siswa</label>
                                                <div className='input-group'>
                                                    <input type="text" className="form-control" name='NamaUser' id="edtNamaUser" value={this.state.Detail.NamaUser || ""} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.NamaUser = e.target.value;
                                                        this.setState({ Detail: data });
                                                    }} readOnly />
                                                    <button type="button" className="btn btn-default" id="btnCari" onClick={() => this.handleCariMurid("")} data-bs-toggle="modal" data-bs-target="#modalCariMurid">
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className="invalid-feedback">Silahkan pilih siswa</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Izin</label>
                                                <select className="form-select" name='Izin' id="edtIzin" value={this.state.Detail.Izin || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Izin = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Jenis Izin</option>
                                                    <option value="Sakit">Sakit</option>
                                                    <option value="Acara Keluarga">Acara Keluarga</option>
                                                    <option value="Lain-lain">Lain-Lain</option>
                                                </select>
                                                <div className="invalid-feedback">Silahkan pilih izin</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Penjelasan</label>
                                                <input type="text" className="form-control" name='Keterangan' id="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Keterangan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className="invalid-feedback">Silahkan pilih keterangan</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>File</label>
                                        <label className='d-flex justify-content-center align-items-center'>
                                            <input id="BackgroundImage" name="File" type='file' accept='image/*' className='d-none' onChange={(e) => this.handlePilihImage(e)} />
                                            <img src={this.state.Images} htmlFor="BackgroundImage" style={{ width: "50%" }} />
                                        </label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalCariMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Siswa</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" onChange={(e) => this.handleCariMurid(e.target.value)} />
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>NISN</th>
                                                <th>Nama</th>
                                                <th>Alamat</th>
                                                <th>Telp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataMurid.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => {
                                                        let data = this.state.Detail;
                                                        data.IDUser = tr.ID;
                                                        data.NamaUser = tr.NAMA;
                                                        data.NISN = tr.NISN;
                                                        this.setState({ Detail: data });
                                                        document.getElementById('btnTutupModalSarana').click();
                                                    }}>
                                                        <td>{tr.NISN}</td>
                                                        <td>{tr.NAMA}</td>
                                                        <td>{tr.ALAMAT}</td>
                                                        <td>{tr.NO_HANDPHONE}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSarana'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Izin</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <tbody>
                                            <tr>
                                                <td>NISN</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NISN}</td>
                                            </tr>
                                            <tr>
                                                <td>Nama Siswa</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NamaUser}</td>
                                            </tr>
                                            <tr>
                                                <td>Kelas</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Kelas}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Izin</td>
                                                <td>:</td>
                                                <td>{tanggalIndo(this.state.Detail.Tanggal)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Buat</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.TimeCreated}</td>
                                            </tr>
                                            <tr>
                                                <td>Izin</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Izin}</td>
                                            </tr>
                                            <tr>
                                                <td>Keterangan</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Keterangan}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="row">
                                        <div className="col-6">
                                            <h6>File</h6>
                                            {
                                                this.state.Detail.File != "" ? <img src={host.replace("siamik/", "") + this.state.Detail.File} width="100%" /> : ""
                                            }
                                        </div>
                                        <div className="col-6">
                                            <h6>TTD Wali murid</h6>
                                            {
                                                this.state.Detail.TTDWaliMurid != "" ? <img src={host.replace("siamik/", "") + this.state.Detail.TTDWaliMurid} width="100%" /> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/tatausaha_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus izin" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Izin</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
