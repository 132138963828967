import React, { Fragment } from 'react';
import { api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';
import { Field } from 'devextreme-react/cjs/data-grid';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataGroup: [],
            Detail: {},
            DataGuru: [],
            DataJurusan: [],
            DataMurid: [],
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/masterkelas_api", { act: "data master kelas", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/masterkelas_api", { act: "detail master kelas", ID: Detail.ID }, true);
        this.setState({ Detail: Detail, DataGuru: sql.guru, DataJurusan: sql.jurusan });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async modalMurid(data) {
        let sql = await api("controler/masterkelas_api", { act: "data master murid", IDKelas: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ DataMurid: sql.data });
            openModal("modalMurid");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ? <Fragment>
                            <div className="row">
                                <div className="col-md-8 main-title">Master Kelas</div>
                                <div className="col-md-4 d-flex gap-1">
                                    <div className="input-group gap-2">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                        <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Kelas' />
                                        <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                group={[
                                    { sort: "Level", cap: "Level" }
                                ]}
                                opt={[
                                    { icon: "fas fa-users", fn: (e) => this.modalMurid(e) },
                                    { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                    { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                ]} />
                        </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master kelas" : "edit master kelas"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Kelas" : "Edit Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kode</label>
                                                <input type="text" className='form-control' name="Kode" id="edtKode" value={this.state.Detail.Kode || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kode = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan kode</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' name="Nama" id="edtNama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nama</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Level</label>
                                                <input type="number" className='form-control' name="Level" id="edtLevel" value={this.state.Detail.Level || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Level = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan level</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>GuruID</label>
                                                <select className='form-select' name="GuruID" id="edtGuruID" value={this.state.Detail.GuruID || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.GuruID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    {
                                                        this.state.DataGuru.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Guru ID</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name="Keterangan" id="edtKeterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan keterangan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Jurusan</label>
                                        <select className='form-select' name="Jurusan" id="edtJurusan" value={this.state.Detail.Jurusan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Jurusan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required>
                                            {
                                                this.state.DataJurusan.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan jurusan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="1">Aktif</option>
                                            <option value="0">Tidak Aktif</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Siswa Dalam Kelas</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataMurid}
                                    thead={[
                                        { cap: "NISN", sort: "NISN", type: "string" },
                                        { cap: "Nama", sort: "NAMA", type: "string" },
                                        { cap: "Alamat", sort: "ALAMAT", type: "string" },
                                        { cap: "Telp", sort: "NO_TELEPHONE", type: "string" },
                                    ]}
                                    group={[
                                        { sort: "Level", cap: "Level" }
                                    ]}
                                    opt={[
                                        { icon: "fas fa-users", fn: (e) => this.modalMurid(e) },
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master kelas" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Menu</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus Kelas ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
