import React, { Fragment } from 'react';
import { api, saiki, openModal } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
import { locale } from 'devextreme/localization';
locale('id');

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Detail: {},
            q: "",
            Akses: true,
            D1: saiki("01"),
            D2: saiki(),
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/tatausaha_api", { act: "data log", D1: this.state.D1, D2: this.state.D2 }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Akses: true });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/tatausaha_api", { act: "detail log", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data });
        openModal("modalDetail");
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
        setTimeout(() => {
            this.handleMain();
        }, 500);
    };

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Log Aktifitas</div>
                                    <div className="col-md-4 d-flex">
                                        <DateRangeBox
                                            defaultValue={[this.state.D1, this.state.D2]}
                                            onValueChanged={this.onCurrentValueChange}
                                        />
                                    </div>
                                </div>
                                <RendTables tbody={this.state.Data}
                                    thead={[
                                        { cap: "Waktu", sort: "TimeCreated", type: "datetime" },
                                        { cap: "Nama", sort: "Nama" },
                                        { cap: "Aktifitas", sort: "Act" },
                                        { cap: "Detail", sort: "", type: "opsi" },
                                    ]}

                                    opt={[
                                        { icon: "fas fa-info", fn: (e) => this.handleModalForm(e) }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <input type='hidden' name="ID" value={this.state.Detail.ID} />
                            <input type='hidden' name="act" value="hapus master mapel" />
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Aktifitas</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
