import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, tanggalIndo } from '../Modul';
import RendTables from '../component/RendTable';
import { Details } from 'devextreme-react/cjs/file-manager';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataTahunAjaran: [],
            IDTahunAjaran: 0,
            q: "",
            Akses: true,
            CTBaru: 0,
            CTBeli: 0,
            CTIsi: 0,
            CTDaftar: 0,
            CTDiterima: 0,
            Opt: [
                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
            ],
            Title: "Data Calon Siswa Daftar Online",
            Mode: "Baru"
        };
    }

    async componentDidMount() {
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }

        let sql = await api("controler/kurikulum_api", { act: "data tahun ajaran" });
        if (sql.status == "sukses") {
            let IDTahunAjaran = 0;
            for (let dd of sql.data) {
                if (dd.Status == "Aktif") IDTahunAjaran = dd.ID;
            }
            this.setState({ DataTahunAjaran: sql.data, IDTahunAjaran: IDTahunAjaran });
            setTimeout(() => {
                this.handleMain();
            }, 500);
        }
    }

    async handleMain() {
        let sql = await api("controler/kurikulum_api", { act: "summary calon siswa", IDTahunAjaran: this.state.IDTahunAjaran, Status: this.state.Mode }, true);
        if (sql.status == "sukses") {
            this.setState({
                CTBaru: sql.baru,
                CTBeli: sql.beli,
                CTIsi: sql.isi,
                CTDaftar: sql.daftar,
                CTDiterima: sql.terima
            });
            this.handleChangeMode("Baru");
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleGetData() {
        let sql = await api("controler/kurikulum_api", { act: "data ppdb", IDTahunAjaran: this.state.IDTahunAjaran, Status: this.state.Mode }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleModalForm(Detail = {}) {
        alert("menu masih dalam pengembangan");
    }

    modalDelete(Detail, act = "hapus tahun ajaran") {
        this.setState({ ID: Detail.ID, DeleteAct: act });
        openModal("modalDelete")
    }

    async modalFormEditSiswa(data) {
        let sql = await api("controler/mastermurid_api", { act: "detail master murid", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data });
            openModal("modalFormEditSiswa");
        }
    }

    handleChangeMode(Mode) {
        if (Mode == "Baru") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.modalFormEditSiswa(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-print", fn: (e) => this.handleModalForm(e), cap: "Cetak" },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), color: "text-danger", cap: "Hapus" }
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "Password", sort: "Pwd", type: "str" },
                { cap: "Status", sort: "Status", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Daftar Online", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Beli Formulir") {
            let Opt = [
                { icon: "fas fa-window-close", fn: (e) => this.handleModalForm(e), color: "text-warning", cap: "Batal" },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), color: "text-danger", cap: "Hapus" }
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Pembeli Formulir", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Sudah Isi Formulir") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-cash-register", fn: (e) => this.handleModalForm(e), cap: "Daftar Ulang" },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), color: "text-danger", cap: "Hapus" },
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Siswa Sudah Isi Formulir", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Daftar Ulang") {
            let Opt = [
                { icon: "fas fa-cash-register", fn: (e) => this.handleModalForm(e), cap: "Terima" },
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Siswa Sudah Daftar Ulang", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Pendaftaran Diterima") {
            let Opt = [
                { icon: "fa-window-close", fn: (e) => this.handleModalForm(e), color: "text-warning", cap: "Batal" },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), color: "text-danger", cap: "Hapus" },
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Diterima", Opt: Opt, Field: Field, Mode: Mode });
        }
        setTimeout(() => {
            this.handleGetData();
        }, 500);
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <Fragment>
                            <div className="row">
                                <div className="col-md-8 main-title">{this.state.Title}</div>
                                <div className="col-md-4 d-flex gap-1">
                                    <select className='form-select form-select-sm' value={this.state.IDTahunAjaran} onChange={(e) => {
                                        this.setState({ IDTahunAjaran: e.target.value });
                                        setTimeout(() => {
                                            this.handleGetData();
                                        }, 500);
                                    }} >
                                        {
                                            this.state.DataTahunAjaran.map((opt, i) => {
                                                return <option key={i} value={opt.ID}>{opt.Nama}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <p></p>
                            <div className="row g-3 justify-content-center">
                                <div className="col-md-2">
                                    <div className="card text-center gradient-success text-white" onClick={() => this.handleChangeMode("Baru")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTBaru} Calon Siswa</p>
                                            <p className="card-text">Pendaftar Online</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-primary text-white" onClick={() => this.handleChangeMode("Beli Formulir")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTBeli} Calon Siswa</p>
                                            <p className="card-text">Pembeli Formulir</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-warning text-white" onClick={() => this.handleChangeMode("Sudah Isi Formulir")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTIsi} Calon Siswa</p>
                                            <p className="card-text">Sudah Isi Formulir</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-danger text-white" onClick={() => this.handleChangeMode("Daftar Ulang")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTDaftar} Calon Siswa</p>
                                            <p className="card-text">Sudah Daftar Ulang</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-dark text-white" onClick={() => this.handleChangeMode("Pendaftaran Diterima")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTDiterima} Calon Siswa</p>
                                            <p className="card-text">Pendaftar Diterima</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                opt={this.state.Opt} />
                        </Fragment>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormEditSiswa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah calon siswa" : "edit calon siswa"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Calon Siswa" : "Edit Calon Siswa"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' name="NAMA" value={this.state.Detail.NAMA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Alamat</label>
                                                <input type="text" className='form-control' name="ALAMAT" value={this.state.Detail.ALAMAT || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>EMAIL</label>
                                                <input type="text" className='form-control' name="EMAIL" value={this.state.Detail.EMAIL || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.EMAIL = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan EMAIL</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="number" className='form-control' name="NO_HANDPHONE" value={this.state.Detail.NO_HANDPHONE || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HANDPHONE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Telp</div>
                                            </div>
                                        </div>
                                        {
                                            this.state.Mode == "Sudah Isi Formulir" ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className='form-group'>
                                                            <label>NIK Siswa</label>
                                                            <input type="text" className='form-control' name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.NIK = e.target.value;
                                                                this.setState({ Detail: data });
                                                            }} required />
                                                            <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className='form-group'>
                                                            <label>NISN</label>
                                                            <input type="number" className='form-control' name="NISN" value={this.state.Detail.NISN || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.NISN = e.target.value;
                                                                this.setState({ Detail: data });
                                                            }} required />
                                                            <div className='invalid-feedback'>Silahkan masukan NISN</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermurid_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus master murid" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Siswa</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan menghapus data ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
